const playtesters = [
	'Michael Anderson',
	'Josh Anthony',
	'​San Ashitaka',
	'​Aaron Aylor',
	'Chris Bateman',
	'Nick Bateman',
	'Stephanie Berendt',
	'​Mike Berkland',
	'Ernest "Moondragon" Besaw',
	'Katie Bishop',
	'Adam Boutz',
	'​Joseph Brezina',
	'Eric Bye',
	'Jacquelyn Cardenuto',
	'Adam Carter',
	'Chris "Xefer" Combs',
	'Quincy Conrad',
	'Duncan Coronado',
	'Sudha Coronado',
	'Paul Courtenay',
	'Mike Damp',
	'Noah Danaher',
	'Scott Davenport',
	'Dominick DeFlippo',
	'Jason Denis',
	'Raymond Diep',
	'Ayla Dinwiddie',
	'Matt Dupont',
	'Ari Eggert',
	'Courtney Ertel',
	'Nicole Fende',
	'Cindy Ferkenhoff',
	'Brandon Firfer',
	'Nick Firth',
	'Sean D Ford',
	'Shannon Ford',
	'Aaron Frank',
	'Arielle Galante',
	'Judith Gastell',
	'Brittney Geenen',
	'Josh Geenen',
	'Anna George',
	'Clayton Goad',
	'Ananda Guneratne',
	'Andrew S. Hall',
	'Ashley Haller',
	'David Harvison',
	'Rich Holliday',
	'Brian Horne',
	'Shane Hose',
	'Nick Jansen',
	'Peter Janssen',
	'Aaron Jensen',
	'Kelly Johnson',
	'Laura Kalsow',
	'Brian Kamstra',
	'Pete Karakis',
	'Cindy Koy',
	'Anne Kwan',
	'Kryshta Lambert',
	'Kyerstin Lambert',
	'Owen Lange',
	'Miles Larson',
	'David LeDuc',
	'Tyler Lehman',
	'Christiana Lewis',
	'Andy Lin',
	'Clare Lin',
	'Allison Lonigro',
	'Eric "Smuggins" Lotos',
	'Marcus Luoma',
	'Kevin Mares',
	'Andy Mauthe',
	'Jack McDonald',
	'Michael McNerney',
	'Tim McPherson',
	'Jackson Melius',
	'Chad Metzger',
	'Chris Murphy',
	'Brian Nemanic',
	'Brad Nord',
	'Kristie Nord',
	'Eli Pace',
	'Keith Pare',
	'Shaun Patrick',
	'Claire Peavey',
	'Michael Perez',
	'David Pomponio',
	'Megan Pomponio',
	'Charlie Popp',
	'Colleen Popp',
	'Joshua Price',
	'Erin Reilly-Sanders',
	'Kevin Rodgers',
	'C David Ross',
	'Jessica Ryan',
	'Lex Ryan',
	'Ellen Schousboe',
	'​Tyler Schumacher',
	'Wendy Seidl',
	'Sumair Sheikh',
	'Bram Smith',
	'JT Smith',
	'Victor Smith',
	'​Cully Smutzler',
	'Larry Snyder',
	'Justin Stephens',
	'Russell Stiriz',
	'Cynthia Stranis',
	'Steve Sykes',
	'Bria Teasley',
	'Keith Teasley',
	'Matt Tinebra',
	'Morgan Tyykila',
	'Scott Vandenberg',
	'John Vestrum',
	'Felix Wagner',
	'Zach Welch',
	'Owen Wokasch',
	'Steven Zagyi',
	'Joseph Zusi',
	'Adam',
	'Chad',
	'FaustWolfmane',
	'Jake',
	'Jesse',
	'Kenner',
	'OtterlyAdorable',
	'Sam',
	'silverravan',
	'Steven (Malingerer)',
	'ZaOndra',
];

export const PlaytesterList = () => {
	return (
		<div className='tsd-green-wrapper'>
			<div className='content-container'>
				<h2>Playtester List</h2>
				<ul>
					{playtesters.map((name) => (
						<li key={name}>{name}</li>
					))}
				</ul>
			</div>
		</div>
	);
};
